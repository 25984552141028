<template>
  <div>
    <div class="banner">
      <img src="@/assets/img/home/banner.png" />
    </div>
    <div class="content center">
      <div class="iconArea">
        <div
          :class="x % 2 === 0 ? 'iconDivider' : 'icon'"
          v-for="x of 9"
          :key="x"
        >
          <img v-if="x % 2 !== 0" :src="iconArr[(x - 1) / 2].src" />
          <div v-if="x % 2 !== 0" class="iconDes">
            {{ iconArr[(x - 1) / 2].label }}
          </div>
        </div>
      </div>
      <div class="hotArea">
        <div class="categories">
          <div class="title">
            <div class="title_cn">热门分类</div>
            <div class="title_en">Popular Categories</div>
          </div>
          <div class="categories_list">
            <div
              class="categories_list_item"
              v-for="(x,index) in categoriesArr"
              :key="x.label"
              @click="toFeatured(index)"
            >
              <div class="categories_list_item_decoration"></div>
              <div class="categories_list_item_label">{{ x.label }}</div>
              <img :src="x.src" />
            </div>
          </div>
        </div>
        <div class="commodity">
          <div class="title">
            <div class="title_cn">热门商品</div>
            <div class="title_en">Hot Commodity</div>
          </div>
          <div class="commodity_list">
            <div
              class="commodity_list_item"
              v-for="x in commodityArr"
              :key="x.label"
              @click="toDetail(x.id)"
            >
              <img :src="x.src" />
              <div class="commodity_list_item_name">{{ x.label }}</div>
              <div class="commodity_list_item_price">
                价格：<span class="col-red">{{ x.price }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeIcon1 from "@/assets/img/home/homeIcon1.png";
import homeIcon2 from "@/assets/img/home/homeIcon2.png";
import homeIcon3 from "@/assets/img/home/homeIcon3.png";
import homeIcon4 from "@/assets/img/home/homeIcon4.png";
import homeIcon5 from "@/assets/img/home/homeIcon5.png";

import categories1 from "@/assets/img/home/categories1.png";
import categories2 from "@/assets/img/home/categories2.png";
import categories3 from "@/assets/img/home/categories3.png";
import categories4 from "@/assets/img/home/categories4.png";
import categories5 from "@/assets/img/home/categories5.png";
import categories6 from "@/assets/img/home/categories6.png";

import commodity1 from "@/assets/img/home/commodity1.png";
import commodity2 from "@/assets/img/home/commodity2.jpg";
import commodity3 from "@/assets/img/home/commodity3.png";
import commodity4 from "@/assets/img/home/commodity4.jpg";
import commodity5 from "@/assets/img/home/commodity5.jpg";
import commodity6 from "@/assets/img/home/commodity6.png";

export default {
  name: "Home",
  data() {
    return {
      iconArr: [
        { label: "品质优选", src: homeIcon1 },
        { label: "工厂价格", src: homeIcon2 },
        { label: "7天无忧退换", src: homeIcon3 },
        { label: "满129免邮费", src: homeIcon4 },
        { label: "24小时人工服务", src: homeIcon5 },
      ],
      categoriesArr: [
        { label: "美妆个护", src: categories1 },
        { label: "居家生活", src: categories2 },
        { label: "精选美食", src: categories3 },
        { label: "数码家电", src: categories4 },
        { label: "厨房用具", src: categories5 },
        { label: "杀菌修护", src: categories6 },
      ],
      commodityArr: [
        { label: "浮晓果蔬消毒液", src: commodity1, price: "￥21.9", id: 'commodity1' },
        { label: "米浮泡沫私护免洗抗菌液", src: commodity2, price: "￥86", id: 'commodity2' },
        { label: "米浮泡沫皮肤抗菌液", src: commodity3, price: "￥66", id: 'sterilize1' },
        { label: "米浮衣物家居消毒液", src: commodity4, price: "￥89", id: 'commodity4' },
        { label: "向妍氨基酸轻柔洁颜泡沫", src: commodity5, price: "￥21.9", id: 'commodity5' },
        {
          label: "米浮专业口腔护理泡沫(薄荷味)",
          src: commodity6,
          price: "￥66",
          id: 'sterilize2'
        },
      ],
    };
  },
  methods:{
    toFeatured(index) {
      this.$router.push({
        name:'Featured',
        query:{
          chosenIndex: index,
        }
      })
    },
    toDetail(id) {
      this.$router.push({
        name:'GoodsDetail',
        params:{
          id,
        }
      })
    },
  }
};
</script>
<style scoped>
.center {
  margin: 0 auto;
  width: 1200px;
}
.banner {
  width: 100%;
}
.banner img {
  width: 100%;
}
.content {
  position: relative;
  padding-top: 168px;
}
.iconArea {
  position: absolute;
  top: -88px;
  left: 0;
  right: 0;
  height: 176px;
  background-color: #fff;
  box-shadow: 0 0 10px 4px rgb(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 50px;
}
.iconDivider {
  width: 1px;
  height: 60px;
  background-color: #d8d8d8;
}
.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.icon img {
  width: 64px;
  height: 64px;
  margin-bottom: 14px;
}
.iconDes {
  font-size: 14px;
  line-height: 28px;
  color: #303030;
}
.title {
  width: 256px;
  height: 100px;
  border: 2px solid #303030;
  color: #303030;
  background-color: #fff;
  position: relative;
  margin: 0 auto 58px;
  padding-top: 6px;
  text-align: center;
}
.title::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 40px;
  background-color: #fff;
  left: -2px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.title::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 40px;
  background-color: #fff;
  right: -2px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.title_cn {
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 2px;
}
.title_en {
  color: rgba(138, 138, 138, 0.26);
  font-size: 24px;
  line-height: 38px;
}

.categories_list {
  width: 100%;
  display: grid;
  grid-template: 1fr 1fr/1fr 1fr 1fr;
  grid-column-gap: 60px;
  grid-row-gap: 36px;
  margin-bottom: 80px;
}
.categories_list_item {
  width: 360px;
  height: 160px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.05);
  position: relative;
  color: #303030;
  font-size: 28px;
  line-height: 40px;
  font-weight: 700;
  padding: 45px 0 0 36px;
}
.categories_list_item_decoration {
  width: 26px;
  height: 4px;
  background-color: #fb4045;
  border-radius: 1px;
  margin-bottom: 26px;
}
.categories_list_item_label {
  text-align: left;
}
.categories_list_item img {
  position: absolute;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
}

.commodity_list {
  width: 100%;
  display: grid;
  grid-template: 1fr 1fr/1fr 1fr 1fr;
  grid-column-gap: 46px;
  grid-row-gap: 36px;
  margin-bottom: 80px;
}
.commodity_list_item {
  width: 370px;
  text-align: center;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.05);
  color: #303030;
  font-size: 24px;
  line-height: 33px;
  font-weight: 700;
}
.commodity_list_item img {
  height: 240px;
  margin-bottom: 30px;
}
.commodity_list_item_name {
  margin-bottom: 14px;
}
.commodity_list_item_price {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}
.col-red {
  color: #fb4045;
}
</style>
